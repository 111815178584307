import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@crate.io/crate-gc-admin';
import { OPERATION_STATES } from '../../../../constants/defaults';
import { ImportJob, ImportJobWithName } from 'types';

export type ImportJobsListActionsProps = {
  importJob: ImportJobWithName;
  onDelete: (importJob: ImportJob) => void;
  onShowDetails: (importJob: ImportJobWithName) => void;
};

function ImportJobsListActions({
  importJob,
  onDelete,
  onShowDetails,
}: ImportJobsListActionsProps) {
  const [isDeleting, setIsDeleting] = useState(false);

  const handleRemoveImportJob = () => {
    setIsDeleting(true);
    onDelete(importJob);
  };

  switch (importJob.status) {
    case OPERATION_STATES.SENT:
    case OPERATION_STATES.REGISTERED:
    case OPERATION_STATES.IN_PROGRESS:
      return (
        <Button
          kind={Button.kinds.TERTIARY}
          size={Button.sizes.SMALL}
          className="text-crate-blue"
          loading={isDeleting}
          onClick={handleRemoveImportJob}
        >
          <FormattedMessage id="common.cancel" />
        </Button>
      );
    default:
      return (
        <Button
          kind={Button.kinds.TERTIARY}
          size={Button.sizes.SMALL}
          className="text-crate-blue"
          onClick={() => onShowDetails(importJob)}
        >
          <FormattedMessage id="cluster.clusterImport.showDetailsButton" />
        </Button>
      );
  }
}

export default ImportJobsListActions;
