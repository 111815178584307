import { Text } from '@crate.io/crate-gc-admin';
import { CONNECTION_FIELD_NAMES } from '../../constants';
import { useIntl } from 'react-intl';
import { Connection, File as CloudFile } from 'types';
import {
  CreateConnectionForm,
  CreateConnectionScramForm,
  CreateConnectionX509Form,
} from './forms';

export type ConnectionDetailsStepSummaryProps = {
  attachedCertificateFile: CloudFile | File | null;
  values: CreateConnectionForm | Connection;
};

function ConnectionDetailsStepSummary({
  attachedCertificateFile,
  values,
}: ConnectionDetailsStepSummaryProps) {
  const { formatMessage } = useIntl();

  const renderValue = (label: string, value: string) => {
    return (
      <div className="flex gap-2">
        <Text className="whitespace-nowrap font-bold">{label}:</Text>
        <Text truncate>{value}</Text>
      </div>
    );
  };

  const renderScramConnectionSummary = (connection: CreateConnectionScramForm) => {
    return (
      <div>
        {renderValue(
          formatMessage({
            id: 'cluster.clusterImportIntegration.connectionDetailsStep.connectionNameLabel',
          }),
          connection.name,
        )}
        {renderValue(
          formatMessage({
            id: 'cluster.clusterImportIntegration.connectionDetailsStep.usernameLabel',
          }),
          connection.username,
        )}
        {renderValue(
          formatMessage({
            id: 'cluster.clusterImportIntegration.connectionDetailsStep.passwordLabel',
          }),
          connection.password.replace(/./g, '*'),
        )}
        {renderValue(
          formatMessage({
            id: 'cluster.clusterImportIntegration.connectionDetailsStep.connectionStringLabel',
          }),
          connection.connection_string,
        )}
        {renderValue(
          formatMessage({
            id: 'cluster.clusterImportIntegration.connectionDetailsStep.defaultDatabaseNameLabel',
          }),
          connection.default_db_name,
        )}
      </div>
    );
  };

  const renderX509ConnectionSummary = (connection: CreateConnectionX509Form) => {
    const fileName = attachedCertificateFile!.name!;

    return (
      <div>
        {renderValue(
          formatMessage({
            id: 'cluster.clusterImportIntegration.connectionDetailsStep.connectionNameLabel',
          }),
          connection.name,
        )}
        {renderValue(
          formatMessage({
            id: 'cluster.clusterImportIntegration.connectionDetailsStep.fileName',
          }),
          fileName,
        )}
        {renderValue(
          formatMessage({
            id: 'cluster.clusterImportIntegration.connectionDetailsStep.connectionStringLabel',
          }),
          connection.connection_string,
        )}
        {renderValue(
          formatMessage({
            id: 'cluster.clusterImportIntegration.connectionDetailsStep.defaultDatabaseNameLabel',
          }),
          connection.default_db_name,
        )}
      </div>
    );
  };

  const renderConnectionSummary = (connection: Connection) => {
    return (
      <div>
        {renderValue(
          formatMessage({
            id: 'cluster.clusterImportIntegration.connectionDetailsStep.connectionLabel',
          }),
          connection.name,
        )}
      </div>
    );
  };

  if ('id' in values) {
    return renderConnectionSummary(values);
  } else if (CONNECTION_FIELD_NAMES.USERNAME in values) {
    return renderScramConnectionSummary(values);
  } else {
    return renderX509ConnectionSummary(values);
  }
}

export default ConnectionDetailsStepSummary;
