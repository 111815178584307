import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import ConstrainWidth from '../../../components/ConstrainWidth/ConstrainWidth';
import GeneralSettings from './GeneralSettings';
import SecretList from './SecretList';
import UserList from './UserList';
import ViewContainer from '../../../components/ViewContainer';
import { getUserIsOrganizationAdmin } from '../../../utils/data/user';
import { useGetUsersMe } from '../../../swrHooks';

function OrganizationSettings() {
  const { organizationId } = useParams();
  const { data: loggedInUser } = useGetUsersMe();

  const userIsOrgAdmin = useMemo(
    () => getUserIsOrganizationAdmin(loggedInUser, organizationId),
    [loggedInUser, organizationId],
  );

  return (
    <ConstrainWidth>
      <ViewContainer
        heading={
          <FormattedMessage id="organization.organizationSettings.pageTitle" />
        }
        render={() => (
          <>
            <GeneralSettings organizationId={organizationId} />
            <UserList organizationId={organizationId} />
            {userIsOrgAdmin && <SecretList organizationId={organizationId} />}
          </>
        )}
      />
    </ConstrainWidth>
  );
}

export default OrganizationSettings;
