import { Button, Label, Text } from '@crate.io/crate-gc-admin';
import { Form, Input, Select } from 'antd';
import { isNull, last } from 'lodash';
import { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { apiPost } from 'src/api';
import { ERROR_TYPES, FileErrorInfo } from 'src/components/FileObject/FileObject';
import {
  FILE_STATES,
  FILE_UPLOAD_FILE_SIZE_LIMIT_BYTES,
} from 'src/constants/defaults';
import { CRATEDB_CLOUD_IMPORT_FILE_DOCS } from 'src/constants/links';
import { useGetOrganizationsIdFiles } from 'src/swrTsHooks';
import { fromBytes } from 'src/utils';
import { File as CloudFile, FileId, UploadFileResponse } from 'types';
import useSupportedFileTypes from '../../common/hooks/useSupportedFileTypes';
import FileDetailsFields from '../../common/FileDetailsForm';
import {
  FIELD_NAMES,
  FILE_COMPRESSION_OPTIONS,
  SupportedFileType,
} from '../../constants';
import fileUploader from '../utils/fileUploader';
import { mapMimeTypeToSupportedFormat } from '../utils/mimeTypeToSupportedFormat';
import { CreateImportJobFileBody } from './form';
import ConstrainStepWidth from '../../../../../components/StepLayout/ConstrainStepWidth';
import TestSWRIsFetching from 'src/components/TestSWRIsFetching';
import FileInput from 'src/components/FileInput';

export type SourceDetailsStepProps = {
  attachedFile: CloudFile | File | null;
  setAttachedFile: (file: CloudFile | File | null) => void;
  defaultValues: CreateImportJobFileBody;
  onNext: (result: CreateImportJobFileBody) => void;
};

function SourceDetailsStep({
  attachedFile,
  setAttachedFile,
  defaultValues,
  onNext,
}: SourceDetailsStepProps) {
  const {
    supportedFileTypes,
    supportedFileFormats,
    fileCompressionTypes,
    fileCompressionFormats,
  } = useSupportedFileTypes();
  const [form] = Form.useForm<CreateImportJobFileBody>();
  const fileId = Form.useWatch<CreateImportJobFileBody>(
    [FIELD_NAMES.FILE_NAMESPACE, FIELD_NAMES.ID],
    form,
  );
  const { organizationId } = useParams();
  const { formatMessage } = useIntl();
  const [fileUploadProgress, setFileUploadProgress] = useState<number | undefined>();
  const [error, setError] = useState<FileErrorInfo | undefined>();

  // SWR
  const {
    data: files,
    mutate: mutateFiles,
    isLoading: isLoadingFiles,
  } = useGetOrganizationsIdFiles(organizationId!);
  const recentlyUploadedFiles =
    files?.filter(file => file.status === FILE_STATES.UPLOADED) || [];

  const uploadRequest = useMemo(() => {
    // this must be memoized to prevent instantiating
    // new requests on every render, if we don't memoize
    // we lose a reference to the XMLHttpRequest and we can't cancel
    return new XMLHttpRequest();
  }, []);

  const handleFileUploadError = (errorMessage: string) => {
    setAttachedFile(null);
    setError({
      message: errorMessage,
      type: ERROR_TYPES.ERROR_POSTING_FILE,
    });
  };

  const handleUploadFile = async (file: File) => {
    // Check file size limit
    setAttachedFile(file);
    if (file.size > FILE_UPLOAD_FILE_SIZE_LIMIT_BYTES) {
      setError({
        message: formatMessage(
          {
            id: 'cluster.clusterImportFile.sourceDetailsStep.maxFileSizeExceededHelp',
          },
          {
            bytes: fromBytes(file.size).format(),
          },
        ),
        type: ERROR_TYPES.ERROR_FILE_SIZE,
      });
      return;
    }

    setFileUploadProgress(0);
    const { success: createFileUploadSuccess, data: createFileUploadData } =
      await apiPost<UploadFileResponse>(
        `/api/v2/organizations/${organizationId}/files/`,
        {
          file_size: file.size,
          name: file.name,
        },
      );

    if (
      !createFileUploadSuccess &&
      createFileUploadData &&
      'message' in createFileUploadData
    ) {
      setFileUploadProgress(undefined);
      handleFileUploadError(createFileUploadData!.message!);
      return;
    }

    const cloudFile = createFileUploadData as CloudFile;
    setAttachedFile(cloudFile);
    form.setFieldValue([FIELD_NAMES.FILE_NAMESPACE, FIELD_NAMES.ID], cloudFile.id);
    mutateFiles([cloudFile, ...(files || [])]);

    try {
      await fileUploader({
        attachedFile: file,
        onUpdate: (percentComplete: number) => {
          // We have the pre-signed URL
          // now as soon as progress starts
          // being reported, change state
          // and pass progress to fileImportForm
          setFileUploadProgress(percentComplete);
        },
        onComplete: () => {
          setFileUploadProgress(undefined);
        },
        request: uploadRequest,
        url: cloudFile.upload_url,
      });
    } catch {
      return;
    }

    prefillFileSpecs(file.name, file.type);
  };

  const prefillFileSpecs = (name: string, type: string) => {
    const fileExtension = last(name.split('.'));
    let compression = 'none';
    let fileType = (type || fileExtension)!;

    if (
      ([...fileCompressionTypes, ...fileCompressionFormats] as string[]).includes(
        fileType,
      )
    ) {
      compression = FILE_COMPRESSION_OPTIONS.GZIP;
      [fileType] = name.split('.').slice(-2, -1);
    }

    if ((supportedFileTypes as string[]).includes(fileType)) {
      fileType = mapMimeTypeToSupportedFormat(fileType as SupportedFileType);
    }

    if ((supportedFileFormats as string[]).includes(fileType)) {
      form.setFieldValue(FIELD_NAMES.COMPRESSION_OPTIONS, compression);
      form.setFieldValue(FIELD_NAMES.FORMAT_OPTIONS, fileType);
    }
  };

  const handleCancelUpload = () => {
    uploadRequest.abort();
    setFileUploadProgress(undefined);
  };

  const handleRemoveFile = () => {
    setAttachedFile(null);
    setError(undefined);
    form.setFieldValue([FIELD_NAMES.FILE_NAMESPACE, FIELD_NAMES.ID], undefined);

    if (!isNull(fileUploadProgress)) {
      handleCancelUpload();
    }
  };

  const handleReimportChange = (fileId: FileId) => {
    const file = recentlyUploadedFiles.find(f => f.id === fileId)!;
    setFileUploadProgress(undefined);
    setAttachedFile(file);
    prefillFileSpecs(file.name!, last(file.name!.split('.'))!);
    form.setFieldValue([FIELD_NAMES.FILE_NAMESPACE, FIELD_NAMES.ID], file.id);
  };

  const getRecentFileLabel = (file: CloudFile) => {
    const size = fromBytes(file.file_size).format();

    return (
      <FormattedMessage
        id="cluster.clusterImportFile.sourceDetailsStep.reimportedFileLabelText"
        values={{
          name: file.name,
          size,
        }}
      />
    );
  };

  const nextButtonEnabled = useMemo(() => {
    return typeof fileId !== 'undefined';
  }, [fileId]);

  const onFormFinish = (values: CreateImportJobFileBody) => {
    onNext(values);
  };

  return (
    <ConstrainStepWidth>
      <Form<CreateImportJobFileBody>
        autoComplete="off"
        disabled={
          typeof error !== 'undefined' || typeof fileUploadProgress !== 'undefined'
        }
        form={form}
        initialValues={{
          ...defaultValues,
        }}
        layout="vertical"
        name="import-data-from-file-source-form"
        id="import-data-from-file-source-form"
        aria-label="import-data-from-file-source-form"
        onFinish={onFormFinish}
        requiredMark="optional"
      >
        <Text pale className="mb-2">
          <FormattedMessage
            id="cluster.clusterImportFile.formattingRulesDocsText"
            values={{
              link: (
                <a
                  href={CRATEDB_CLOUD_IMPORT_FILE_DOCS}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage id="cluster.clusterImportFile.docsLinkPartial" />
                </a>
              ),
            }}
          />
        </Text>

        <div>
          {/* hidden field type */}
          <Form.Item name={[FIELD_NAMES.FILE_NAMESPACE, FIELD_NAMES.ID]} hidden>
            <Input />
          </Form.Item>

          <div className="mb-8 flex flex-col gap-2">
            <div aria-live="polite" role="region">
              <FileInput
                file={attachedFile}
                error={error}
                uploadProgress={fileUploadProgress}
                onChange={handleUploadFile}
                onRemove={handleRemoveFile}
                fileDetailsMessage={
                  <Text pale>
                    <FormattedMessage id="cluster.clusterImportFile.sourceDetailsStep.allowedFileSizeText" />
                    <FormattedMessage id="cluster.clusterImportFile.sourceDetailsStep.allowedFileFormatsText" />
                  </Text>
                }
              />
            </div>

            {recentlyUploadedFiles &&
              recentlyUploadedFiles.length > 0 &&
              attachedFile === null && (
                <div>
                  <div className="flex items-center gap-2">
                    <hr className="flex grow" />
                    <Text pale>or</Text>
                    <hr className="flex grow" />
                  </div>

                  <Label>
                    <FormattedMessage id="cluster.clusterImportFile.sourceStep.recentFiles" />
                  </Label>

                  <Select
                    className="mt-[8px] w-full"
                    data-testid="recent-file-select"
                    onChange={handleReimportChange}
                    options={recentlyUploadedFiles.map(f => ({
                      label: getRecentFileLabel(f),
                      value: f.id,
                    }))}
                    optionLabelProp="label"
                    placeholder={
                      <FormattedMessage id="cluster.clusterImportFile.sourceDetailsStep.selectReimportPlaceholderText" />
                    }
                  />
                </div>
              )}
          </div>

          <FileDetailsFields />
        </div>

        <div className="mt-4 flex">
          <Button type={Button.types.SUBMIT} disabled={!nextButtonEnabled}>
            <FormattedMessage id="common.next" />
          </Button>
        </div>
      </Form>

      <TestSWRIsFetching fetchStatusList={[isLoadingFiles]} />
    </ConstrainStepWidth>
  );
}

export default SourceDetailsStep;
