const messages = {
  'cluster.clusterImportIntegration.title': 'Create Integration',
  'cluster.clusterImportIntegration.creatingIntegration': 'Creating integration',
  'cluster.clusterImportIntegration.integrationCreated': 'Integration created',
  'cluster.clusterImportIntegration.errorCreatingIntegration':
    'Error creating integration',
  'cluster.clusterImportIntegration.errorCreatingConnection':
    'Error creating connection',

  // source step
  'cluster.clusterImportIntegration.sourceStep.title': 'Source',
  'cluster.clusterImportIntegration.sourceStep.mongoDb': 'MongoDB',

  // connection
  'cluster.clusterImportIntegration.connectionDetailsStep.title': 'Connection',
  'cluster.clusterImportIntegration.connectionDetailsStep.authLabel':
    'Authentication method',
  'cluster.clusterImportIntegration.connectionDetailsStep.authRequiredText':
    'Authentication method is required',
  'cluster.clusterImportIntegration.connectionDetailsStep.selectAuth':
    'Select authentication method',
  'cluster.clusterImportIntegration.connectionDetailsStep.connectionNameLabel':
    'Connection name',
  'cluster.clusterImportIntegration.connectionDetailsStep.connectionNamePlaceholder':
    'my_connection',
  'cluster.clusterImportIntegration.connectionDetailsStep.usernameLabel': 'Username',
  'cluster.clusterImportIntegration.connectionDetailsStep.usernamePlaceholder':
    'my_user',
  'cluster.clusterImportIntegration.connectionDetailsStep.passwordLabel': 'Password',
  'cluster.clusterImportIntegration.connectionDetailsStep.passwordPlaceholder':
    '*******',
  'cluster.clusterImportIntegration.connectionDetailsStep.connectionStringLabel':
    'Connection string',
  'cluster.clusterImportIntegration.connectionDetailsStep.connectionStringUserPassPlaceholder':
    'mongodb+srv://<user>:<pwd>@hostname/db?opt=1',
  'cluster.clusterImportIntegration.connectionDetailsStep.connectionStringCertificatePlaceholder':
    'mongodb+srv://hostname/db?x509=true',
  'cluster.clusterImportIntegration.connectionDetailsStep.defaultDatabaseNameLabel':
    'Default database name',
  'cluster.clusterImportIntegration.connectionDetailsStep.defaultDatabaseNamePlaceholder':
    'my_db',
  'cluster.clusterImportIntegration.connectionDetailsStep.createNewConnection':
    'Create new connection',
  'cluster.clusterImportIntegration.connectionDetailsStep.selectOrCreateNewConnection':
    'Select or create new connection',
  'cluster.clusterImportIntegration.connectionDetailsStep.authenticationMethod':
    'Authentication method',
  'cluster.clusterImportIntegration.connectionDetailsStep.fileName': 'File name',
  'cluster.clusterImportIntegration.connectionDetailsStep.connectionLabel':
    'Connection',
  // collection
  'cluster.clusterImportIntegration.collectionDetailsStep.title': 'Collection',
  'cluster.clusterImportIntegration.collectionDetailsStep.databaseLabel':
    'MongoDB Database name',
  'cluster.clusterImportIntegration.collectionDetailsStep.databasePlaceholder':
    'my_db',
  'cluster.clusterImportIntegration.collectionDetailsStep.collectionLabel':
    'MongoDB Collection name',
  'cluster.clusterImportIntegration.collectionDetailsStep.collectionPlaceholder':
    'my_collection',

  // table
  'cluster.clusterImportIntegration.tableStep.title': 'Table',
  'cluster.clusterImportIntegration.tableStep.tableLabel': 'Table name',
  'cluster.clusterImportIntegration.tableStep.tableNamePlaceholder':
    'my_import_data',
  'cluster.clusterImportIntegration.tableStep.typeMapping': 'Type Mapping',
  'cluster.clusterImportIntegration.tableStep.selectTypeMapping':
    'Select type mapping',
  'cluster.clusterImportIntegration.tableStep.typeMappingRequiredText':
    'Type mapping is required',

  // integration settings
  'cluster.clusterImportIntegration.integrationSettingsStep.title':
    'Integration settings',
  'cluster.clusterImportIntegration.integrationSettingsStep.nameLabel': 'Name',
  'cluster.clusterImportIntegration.integrationSettingsStep.namePlaceholder':
    'integration_name',
  'cluster.clusterImportIntegration.integrationSettingsStep.mode': 'Mode',
  'cluster.clusterImportIntegration.integrationSettingsStep.selectMode':
    'Select mode',
  'cluster.clusterImportIntegration.integrationSettingsStep.modeRequiredText':
    'Mode is required',
  'cluster.clusterImportIntegration.integrationSettingsStep.createIntegration':
    'Create integration',
};

export default messages;
