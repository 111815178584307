import useSWR from 'swr';
import useSWRInfinite from 'swr/infinite';
import { SUBSCRIPTION_PROVIDER } from './constants/defaults';
import swrCORSFetch from './utils/swrCORSFetch';

export const useGetClusters = () => {
  return useSWR('/api/v2/clusters/', { refreshInterval: 10 * 1000 });
};

export const useGetClustersId = clusterId => {
  return useSWR(clusterId ? `/api/v2/clusters/${clusterId}/` : null, {
    refreshInterval: 10 * 1000,
  });
};

export const useGetClustersIdAvailableproducts = clusterId => {
  return useSWR(
    clusterId ? `/api/v2/clusters/${clusterId}/available-products/` : null,
  );
};

export const useGetClustersIdAvailableupgrades = clusterId => {
  return useSWR(
    clusterId ? `/api/v2/clusters/${clusterId}/available-upgrades/` : null,
  );
};

export const useGetClustersIdExportjobs = clusterId => {
  return useSWR(clusterId ? `/api/v2/clusters/${clusterId}/export-jobs/` : null, {
    refreshInterval: data => {
      return data &&
        data.some(j => ['IN_PROGRESS', 'SENT', 'REGISTERED'].includes(j.status))
        ? 3 * 1000 // poll faster if exports are in progress
        : 10 * 1000;
    },
  });
};

export const useGetClustersIdMetricsCratecpuseconds = clusterId => {
  return useSWR(
    clusterId
      ? `/api/v2/clusters/${clusterId}/metrics/crate_cpu_seconds/?minutes_ago=60`
      : null,
    { refreshInterval: 10 * 1000 },
  );
};

export const useGetClustersIdMetricsCratediskusage = clusterId => {
  return useSWR(
    clusterId
      ? `/api/v2/clusters/${clusterId}/metrics/crate_disk_usage2/?minutes_ago=60`
      : null,
    { refreshInterval: 10 * 1000 },
  );
};

export const useGetClustersIdMetricsCratequerycount = clusterId => {
  return useSWR(
    clusterId
      ? `/api/v2/clusters/${clusterId}/metrics/crate_query_count/?minutes_ago=60`
      : null,
    { refreshInterval: 10 * 1000 },
  );
};

export const useGetClustersIdMetricsCrateunreplicatedtables = clusterId => {
  return useSWR(
    clusterId
      ? `/api/v2/clusters/${clusterId}/metrics/crate_unreplicated_tables/?minutes_ago=2`
      : null,
    { refreshInterval: 2 * 60 * 1000 },
  );
};

export const useGetClustersIdOperations = clusterId => {
  return useSWR(clusterId ? `/api/v2/clusters/${clusterId}/operations/` : null, {
    refreshInterval: 10 * 1000,
  });
};

export const useGetClustersIdSnapshots = (clusterId, start, end) => {
  return useSWR(
    clusterId
      ? `/api/v2/clusters/${clusterId}/snapshots/?start=${start}&end=${end}`
      : null,
  );
};

export const useGetFeaturesStatus = () => {
  return useSWR('/api/v2/features/status/');
};

export const useGetMetaCratedbversions = () => {
  return useSWR('/api/v2/meta/cratedb-versions/');
};

export const useGetMetaIpaddress = () => {
  return useSWR('/api/v2/meta/ip-address/', {
    refreshInterval: 15 * 60 * 1000,
  });
};

export const useGetOrganizations = () => {
  return useSWR('/api/v2/organizations/', {
    refreshInterval: 5 * 60 * 1000,
  });
};

export const useGetOrganizationsId = organizationId => {
  return useSWR(organizationId ? `/api/v2/organizations/${organizationId}/` : null);
};

export const useGetOrganizationsIdAuditlogs = (organizationId, filters) => {
  const getKey = (_, previousPageData) => {
    // convert filters to querystring, omitting null values
    const querystring = new URLSearchParams({
      ...Object.keys(filters)
        .filter(key => filters[key] !== null)
        .reduce((prev, key) => ({ ...prev, [key]: filters[key] }), {}),
    }).toString();

    // find the last id of the previous page (if it exists)
    const lastId =
      previousPageData && previousPageData.length > 0
        ? previousPageData[previousPageData.length - 1].id
        : '';

    // construct the url
    return `/api/v2/organizations/${organizationId}/auditlogs/?${querystring}&last=${lastId}`;
  };

  return useSWRInfinite(organizationId && filters ? getKey : null);
};

export const useGetOrganizationsIdClusters = (organizationId, options = {}) => {
  return useSWR(
    organizationId ? `/api/v2/organizations/${organizationId}/clusters/` : null,
    options,
  );
};

export const useGetOrganizationsIdCurrentconsumption = organizationId => {
  return useSWR(
    organizationId
      ? `/api/v2/organizations/${organizationId}/consumption/current-month/`
      : null,
  );
};

export const useGetOrganizationsIdCredits = organizationId => {
  return useSWR(
    organizationId ? `/api/v2/organizations/${organizationId}/credits/` : null,
  );
};

export const useGetOrganizationsIdCustomer = organizationId => {
  return useSWR(
    organizationId ? `/api/v2/organizations/${organizationId}/customer/` : null,
  );
};

export const useGetOrganizationsIdInvitations = organizationId => {
  return useSWR(
    organizationId
      ? `/api/v2/organizations/${organizationId}/invitations/?status=PENDING`
      : null,
  );
};

export const useGetOrganizationsIdPaymentmethods = organizationId => {
  return useSWR(
    organizationId
      ? `/api/v2/organizations/${organizationId}/payment-methods/`
      : null,
  );
};

export const useGetOrganizationsIdQuotas = organizationId => {
  return useSWR(
    organizationId ? `/api/v2/organizations/${organizationId}/quotas/` : null,
  );
};

export const useGetOrganizationsIdRegions = organizationId => {
  return useSWR(
    organizationId ? `/api/v2/organizations/${organizationId}/regions/` : null,
    {
      refreshInterval: 5 * 60 * 1000,
    },
  );
};

export const useGetOrganizationsIdRemainingbudget = organizationId => {
  return useSWR(
    organizationId
      ? `/api/v2/organizations/${organizationId}/remaining-budget/`
      : null,
    {
      refreshInterval: 5 * 60 * 1000,
    },
  );
};

export const useGetOrganizationsIdRemainingbudgetExcludeclusterid = (
  organizationId,
  clusterId,
) => {
  return useSWR(
    organizationId && clusterId
      ? `/api/v2/organizations/${organizationId}/remaining-budget/?exclude_cluster_id=${clusterId}`
      : null,
  );
};

export const useGetOrganizationsIdSubscriptions = organizationId => {
  return useSWR(
    organizationId ? `/api/v2/organizations/${organizationId}/subscriptions/` : null,
  );
};

export const useGetOrganizationsIdUsers = organizationId => {
  return useSWR(
    organizationId ? `/api/v2/organizations/${organizationId}/users/` : null,
  );
};

export const useGetProducts = () => {
  return useSWR('/api/v2/products/');
};

export const useGetProductsClustersPrice = (
  organizationId,
  region,
  product,
  scaleUnit,
  storageBytes,
) => {
  const query = {
    organization_id: organizationId,
    product_offer: 'stripe',
    region: region?.name,
  };

  if (region?.is_edge_region) {
    query.product_name = 'edge';
    query.product_tier = 'basic';
    query.product_unit = 0;
  } else {
    query.product_name = product?.name;
    query.product_tier = product?.tier;
    query.product_unit = scaleUnit;
    query.storage_bytes = storageBytes;
  }

  const priceUrl =
    !region ||
    (!region.is_edge_region && !(product && scaleUnit !== null && storageBytes))
      ? null
      : new URLSearchParams(query).toString();

  return useSWR(priceUrl ? `/api/v2/products/clusters/price?${priceUrl}` : null);
};

export const useGetProjects = () => {
  return useSWR('/api/v2/projects/');
};

export const useGetRegionsNameInstalltoken = regionName => {
  return useSWR(regionName ? `/api/v2/regions/${regionName}/install-token/` : null);
};

export const useGetRoles = () => {
  return useSWR('/api/v2/roles/');
};

export const useGetStripeOrganizationsIdBillinginformation = organizationId => {
  return useSWR(
    organizationId
      ? `/api/v2/stripe/organizations/${organizationId}/billing-information/`
      : null,
  );
};

export const useGetStripeOrganizationsIdCards = organizationId => {
  return useSWR(
    organizationId ? `/api/v2/stripe/organizations/${organizationId}/cards/` : null,
  );
};

export const useGetSubscriptionsId = subscriptionId => {
  return useSWR(subscriptionId ? `/api/v2/subscriptions/${subscriptionId}/` : null);
};

export const useGetStripeSubscriptionsIdInvoices = subscription => {
  return useSWR(
    subscription && subscription.provider === SUBSCRIPTION_PROVIDER.STRIPE
      ? `/api/v2/stripe/subscriptions/${subscription.id}/invoices/`
      : null,
  );
};

export const useGetStripeSubscriptionsIdInvoicesUpcoming = subscription => {
  return useSWR(
    subscription &&
      (subscription.provider === SUBSCRIPTION_PROVIDER.STRIPE ||
        subscription.provider === SUBSCRIPTION_PROVIDER.STRIPE_BANK_TRANSFER)
      ? `/api/v2/stripe/subscriptions/${subscription.id}/invoices/upcoming/`
      : null,
  );
};

export const useGetUsersMe = () => {
  return useSWR('/api/v2/users/me/');
};

export const useGetUsersMeApikeys = () => {
  return useSWR('/api/v2/users/me/api-keys/');
};

export const useGCGetHealth = url => {
  return useSWR(url ? `${url}/api/health` : null, swrCORSFetch, {
    refreshInterval: 10 * 1000,
  });
};
