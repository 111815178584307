import { INTEGRATION_TYPES } from 'src/constants/integrations';
import logoMongoDB from '../../../../../assets/logo_mongodb.svg';

export const INTEGRATION_SOURCES = [
  {
    title: 'cluster.clusterImportIntegration.sourceStep.mongoDb',
    image: <img src={logoMongoDB} alt="mongodb logo" className="size-10" />,
    icon: (
      <img src={logoMongoDB} alt="mongodb icon" className="inline-flex size-4" />
    ),
    key: INTEGRATION_TYPES.MONGODB,
  },
];
