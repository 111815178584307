import React from 'react';
import { useParams } from 'react-router-dom';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { Loader } from '@crate.io/crate-gc-admin';
import SectionContainer from '../SectionContainer';
import { fromCents } from '../../utils';
import { useGetOrganizationsIdCredits } from '../../swrHooks';
import { CREDITS_STATES, PRICE_OPTIONS_2DP } from '../../constants/defaults';

function CreditsRemaining() {
  const { organizationId } = useParams();
  const { formatNumber } = useIntl();
  const { data: credits, isLoading: creditsLoading } =
    useGetOrganizationsIdCredits(organizationId);

  // to prevent display silliness, we're only interested
  // in ACTIVE rows with a positive balance
  const activeRows = credits
    ? credits
        .filter(credit => credit.status === CREDITS_STATES.ACTIVE)
        .filter(credit => credit.remaining_amount > 0)
        .sort((a, b) => new Date(a.expiration_date) - new Date(b.expiration_date))
    : [];
  const totalValue = credits
    ? activeRows.reduce((prev, next) => {
        return prev + next.remaining_amount;
      }, 0)
    : 0;

  return (
    <SectionContainer>
      {/* display the total credits value */}
      <div className="flex justify-between">
        <div>
          <FormattedMessage id="organization.consumption.creditsRemainingText" />
        </div>
        <div data-testid="remaining-credits">
          {creditsLoading && (
            <Loader size={Loader.sizes.SMALL} color={Loader.colors.PRIMARY} />
          )}
          {!creditsLoading && formatNumber(fromCents(totalValue), PRICE_OPTIONS_2DP)}
        </div>
      </div>

      {/* when there is only one credit row, display the expiration date */}
      {activeRows.length === 1 && (
        <div className="text-xs text-neutral-500" data-testid="single-credit-detail">
          <FormattedMessage
            id="pricingBreakdown.freeCreditsExpiryText"
            values={{
              date: (
                <FormattedDate
                  value={credits[0].expiration_date}
                  day="numeric"
                  month="long"
                  year="numeric"
                />
              ),
            }}
          />
        </div>
      )}

      {/* when there are more than one credit row, display each row */}
      {activeRows.length > 1 && (
        <div
          className="text-xs text-neutral-500"
          data-testid="multiple-credit-detail"
        >
          {activeRows.map(credit => (
            <div>
              <FormattedMessage
                id="pricingBreakdown.freeCreditsBreakdownText"
                values={{
                  value: formatNumber(
                    fromCents(credit.remaining_amount),
                    PRICE_OPTIONS_2DP,
                  ),
                  date: (
                    <FormattedDate
                      value={credit.expiration_date}
                      day="numeric"
                      month="long"
                      year="numeric"
                    />
                  ),
                }}
              />
            </div>
          ))}
        </div>
      )}
    </SectionContainer>
  );
}

export default CreditsRemaining;
