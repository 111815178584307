import { Heading } from '@crate.io/crate-gc-admin';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

export type CardSelectorItem<K extends string> = {
  title: string;
  image: React.ReactNode;
  key: K;
};
export type CardSelectorProps<K extends string> = {
  items: CardSelectorItem<K>[];
  value?: K;
  onChange?: (key: K) => void;
};

function CardSelector<K extends string>({
  items,
  value,
  onChange,
}: CardSelectorProps<K>) {
  const handleItemSelection = (key: K) => {
    if (onChange) {
      onChange(key);
    }
  };

  return (
    <div className="my-2 flex flex-wrap gap-2">
      {items.map(item => {
        return (
          <button
            key={item.key}
            data-testid={item.key}
            className={cn(
              'rounded border',
              'min-h-[124px] w-[150px] p-3',
              'flex flex-col items-center justify-center',
              {
                'border-crate-border-dark bg-crate-blue text-white':
                  value === item.key,
              },
            )}
            onClick={() => {
              handleItemSelection(item.key);
            }}
          >
            <div>{item.image}</div>
            <Heading
              level={Heading.levels.h5}
              className={cn('mt-2', {
                'text-white': value === item.key,
              })}
            >
              <FormattedMessage id={item.title} />
            </Heading>
          </button>
        );
      })}
    </div>
  );
}

export default CardSelector;
