import { Connection, File as CloudFile, ConnectionId, OrganizationId } from 'types';
import {
  CREATE_SCRAM_CONNECTION_DEFAULT_FIELD_VALUES,
  CREATE_X509_CONNECTION_DEFAULT_FIELD_VALUES,
  CreateConnectionForm,
  CreateConnectionScramForm,
  CreateConnectionX509Form,
} from './forms';
import ConstrainStepWidth from 'src/components/StepLayout/ConstrainStepWidth';
import LoadingContainer from 'src/components/LoadingContainer';
import { FormattedMessage, useIntl } from 'react-intl';
import { useState } from 'react';
import { Button, Label, Text } from '@crate.io/crate-gc-admin';
import { Form, Input, Select } from 'antd';
import {
  AUTH_TYPES,
  AUTH_TYPE_OPTIONS,
  AuthTypeOption,
  CONNECTION_FIELD_NAMES,
  MONGODB_PASSWORD_PLACEHOLDER,
  MONGODB_COMPLETE_SCRAM_CONNECTION_STRING_REGEX,
  MONGODB_USERNAME_PLACEHOLDER,
} from '../../constants';
import INPUT_SANITIZATION from '../../../../../constants/inputSanitization';
import FileInput from 'src/components/FileInput';
import { useGetClustersIdConnections } from 'src/swrTsHooks';

export type ConnectionDetailsStepProps = {
  organizationId: OrganizationId;
  connectionDetails?: CreateConnectionForm | Connection;
  attachedCertificateFile: CloudFile | File | null;
  setAttachedCertificateFile: (file: CloudFile | File | null) => void;
  onNext: (result: CreateConnectionForm | Connection) => void;
};

function ConnectionDetailsStep({
  organizationId,
  connectionDetails,
  attachedCertificateFile,
  setAttachedCertificateFile,
  onNext,
}: ConnectionDetailsStepProps) {
  const { formatMessage } = useIntl();

  // forms
  const [scramForm] = Form.useForm<CreateConnectionScramForm>();
  const [x509Form] = Form.useForm<CreateConnectionX509Form>();

  // state
  const [connection, setConnection] = useState<'new' | ConnectionId>();
  const [authType, setAuthType] = useState<AuthTypeOption>(AUTH_TYPES.SCRAM);

  // swr
  const { data: connections, isLoading: isLoadingConnections } =
    useGetClustersIdConnections(organizationId);

  const onScramFormFinish = (values: CreateConnectionScramForm) => {
    onNext(values);
  };

  const onX509FormFinish = (values: CreateConnectionX509Form) => {
    onNext(values);
  };

  const handleChangeScramConnectionString = (connectionString: string) => {
    const connectionStringMatch = connectionString.match(
      MONGODB_COMPLETE_SCRAM_CONNECTION_STRING_REGEX,
    );

    const username = connectionStringMatch?.groups?.username || null;
    const password = connectionStringMatch?.groups?.password || null;

    const fields: Record<string, string> = {};

    if (username && username !== MONGODB_USERNAME_PLACEHOLDER) {
      fields[CONNECTION_FIELD_NAMES.USERNAME] = username;
    }
    if (password && password !== MONGODB_PASSWORD_PLACEHOLDER) {
      fields[CONNECTION_FIELD_NAMES.PASSWORD] = password;
    }
    if (username || password) {
      connectionString = connectionString.replace(`${username}:${password}@`, '');
      fields[CONNECTION_FIELD_NAMES.CONNECTION_STRING] = connectionString;
    }

    scramForm.setFieldsValue(fields);
  };

  const handleCertificateUpload = async (file: File) => {
    setAttachedCertificateFile(file);
    const reader = new FileReader();

    reader.onload = (e: ProgressEvent<FileReader>) => {
      const file = e.target!.result as string;

      const lines = file.split('\n').filter(line => line.length > 0);
      const certificateString = lines.slice(1, lines.length - 1).join('\n');

      x509Form.setFieldValue(CONNECTION_FIELD_NAMES.CERTIFICATE, certificateString);
    };
    reader.readAsText(file);
  };

  const handleCertificateRemove = () => {
    x509Form.resetFields([CONNECTION_FIELD_NAMES.CERTIFICATE]);
    setAttachedCertificateFile(null);
  };

  const createConnection = connection === 'new';

  return (
    <ConstrainStepWidth>
      <LoadingContainer
        loading={isLoadingConnections}
        render={() => {
          if (connections && connections.length === 0) {
            setConnection('new');
          }

          return (
            <>
              <div className="mb-4">
                <Select
                  value={connection}
                  disabled={connections?.length === 0}
                  onChange={(value: 'new' | ConnectionId) => {
                    setAttachedCertificateFile(null);
                    setConnection(value);
                  }}
                  placeholder={
                    <FormattedMessage id="cluster.clusterImportIntegration.connectionDetailsStep.selectOrCreateNewConnection" />
                  }
                  data-testid="connection-select"
                  className="mt-[8px] block"
                  options={[
                    {
                      label: formatMessage({
                        id: 'cluster.clusterImportIntegration.connectionDetailsStep.createNewConnection',
                      }),
                      value: 'new',
                    },
                    ...(connections || []).map(connection => {
                      return {
                        label: connection.name,
                        value: connection.id,
                      };
                    }),
                  ]}
                />
              </div>

              {createConnection && (
                <div className="mb-4">
                  <Label>
                    <FormattedMessage id="cluster.clusterImportIntegration.connectionDetailsStep.authenticationMethod" />
                  </Label>
                  <Select
                    disabled
                    value={authType}
                    onChange={(value: AuthTypeOption) => {
                      setAuthType(value);
                    }}
                    data-testid="auth-type-select"
                    placeholder={
                      <FormattedMessage id="cluster.clusterImportIntegration.connectionDetailsStep.selectAuth" />
                    }
                    className="mt-[8px] block"
                    options={AUTH_TYPE_OPTIONS}
                  />
                </div>
              )}

              {createConnection && authType === AUTH_TYPES.SCRAM && (
                <Form<CreateConnectionScramForm>
                  autoComplete="off"
                  form={scramForm}
                  initialValues={{
                    ...CREATE_SCRAM_CONNECTION_DEFAULT_FIELD_VALUES,
                    ...(connectionDetails || {}),
                  }}
                  layout="vertical"
                  name="mongodb-integration-scram-connection-form"
                  id="mongodb-integration-scram-connection-form"
                  aria-label="mongodb-integration-scram-connection-form"
                  onFinish={onScramFormFinish}
                  requiredMark="optional"
                >
                  {/* hidden auth */}
                  <Form.Item name={[CONNECTION_FIELD_NAMES.AUTH]} hidden>
                    <Input />
                  </Form.Item>

                  <Form.Item<CreateConnectionScramForm>
                    colon={false}
                    label={
                      <FormattedMessage id="cluster.clusterImportIntegration.connectionDetailsStep.connectionNameLabel" />
                    }
                    name={[CONNECTION_FIELD_NAMES.NAME]}
                    rules={[INPUT_SANITIZATION.GENERIC_REQUIRED_TEXT]}
                  >
                    <Input
                      placeholder={formatMessage({
                        id: 'cluster.clusterImportIntegration.connectionDetailsStep.connectionNamePlaceholder',
                      })}
                    />
                  </Form.Item>

                  <div className="lg:grid lg:grid-cols-2 lg:gap-x-4">
                    <Form.Item<CreateConnectionScramForm>
                      colon={false}
                      label={
                        <FormattedMessage id="cluster.clusterImportIntegration.connectionDetailsStep.usernameLabel" />
                      }
                      name={[CONNECTION_FIELD_NAMES.USERNAME]}
                      rules={[INPUT_SANITIZATION.GENERIC_REQUIRED_TEXT]}
                    >
                      <Input
                        placeholder={formatMessage({
                          id: 'cluster.clusterImportIntegration.connectionDetailsStep.usernamePlaceholder',
                        })}
                      />
                    </Form.Item>

                    <Form.Item<CreateConnectionScramForm>
                      colon={false}
                      label={
                        <FormattedMessage id="cluster.clusterImportIntegration.connectionDetailsStep.passwordLabel" />
                      }
                      name={[CONNECTION_FIELD_NAMES.PASSWORD]}
                      rules={[INPUT_SANITIZATION.GENERIC_REQUIRED_TEXT]}
                    >
                      <Input.Password
                        placeholder={formatMessage({
                          id: 'cluster.clusterImportIntegration.connectionDetailsStep.passwordPlaceholder',
                        })}
                        autoComplete="new-password"
                      />
                    </Form.Item>
                  </div>

                  <Form.Item<CreateConnectionScramForm>
                    colon={false}
                    label={
                      <FormattedMessage id="cluster.clusterImportIntegration.connectionDetailsStep.connectionStringLabel" />
                    }
                    name={[CONNECTION_FIELD_NAMES.CONNECTION_STRING]}
                    rules={[INPUT_SANITIZATION.MONGODB_SCRAM_CONNECTION_STRING]}
                  >
                    <Input
                      placeholder={formatMessage({
                        id: 'cluster.clusterImportIntegration.connectionDetailsStep.connectionStringUserPassPlaceholder',
                      })}
                      onBlur={e => handleChangeScramConnectionString(e.target.value)}
                    />
                  </Form.Item>

                  <Form.Item<CreateConnectionX509Form>
                    colon={false}
                    label={
                      <FormattedMessage id="cluster.clusterImportIntegration.connectionDetailsStep.defaultDatabaseNameLabel" />
                    }
                    name={[CONNECTION_FIELD_NAMES.DEFAULT_DATABASE_NAME]}
                    rules={[INPUT_SANITIZATION.MONGODB_DATABASE_NAME]}
                  >
                    <Input
                      placeholder={formatMessage({
                        id: 'cluster.clusterImportIntegration.connectionDetailsStep.defaultDatabaseNamePlaceholder',
                      })}
                    />
                  </Form.Item>

                  <Button type={Button.types.SUBMIT}>
                    <FormattedMessage id="common.next" />
                  </Button>
                </Form>
              )}

              {createConnection && authType === AUTH_TYPES.X509 && (
                <Form<CreateConnectionX509Form>
                  autoComplete="off"
                  form={x509Form}
                  initialValues={{
                    ...CREATE_X509_CONNECTION_DEFAULT_FIELD_VALUES,
                    ...(connectionDetails || {}),
                  }}
                  layout="vertical"
                  name="mongodb-integration-x509-connection-form"
                  id="mongodb-integration-x509-connection-form"
                  aria-label="mongodb-integration-x509-connection-form"
                  onFinish={onX509FormFinish}
                  requiredMark="optional"
                >
                  {/* hidden auth */}
                  <Form.Item name={[CONNECTION_FIELD_NAMES.AUTH]} hidden>
                    <Input />
                  </Form.Item>

                  {/* hidden certificate */}
                  <Form.Item name={[CONNECTION_FIELD_NAMES.CERTIFICATE]} hidden>
                    <Input />
                  </Form.Item>

                  <Form.Item<CreateConnectionX509Form>
                    colon={false}
                    label={
                      <FormattedMessage id="cluster.clusterImportIntegration.connectionDetailsStep.connectionNameLabel" />
                    }
                    name={[CONNECTION_FIELD_NAMES.NAME]}
                    rules={[INPUT_SANITIZATION.GENERIC_REQUIRED_TEXT]}
                  >
                    <Input
                      placeholder={formatMessage({
                        id: 'cluster.clusterImportIntegration.connectionDetailsStep.connectionNamePlaceholder',
                      })}
                    />
                  </Form.Item>

                  <FileInput
                    file={attachedCertificateFile}
                    accept=".pem"
                    className="mb-4"
                    onChange={handleCertificateUpload}
                    onRemove={handleCertificateRemove}
                    fileDetailsMessage={<Text pale>Upload a .pem file</Text>}
                  />

                  <Form.Item<CreateConnectionX509Form>
                    colon={false}
                    label={
                      <FormattedMessage id="cluster.clusterImportIntegration.connectionDetailsStep.connectionStringLabel" />
                    }
                    name={[CONNECTION_FIELD_NAMES.CONNECTION_STRING]}
                    rules={[INPUT_SANITIZATION.MONGODB_X509_CONNECTION_STRING]}
                  >
                    <Input
                      placeholder={formatMessage({
                        id: 'cluster.clusterImportIntegration.connectionDetailsStep.connectionStringCertificatePlaceholder',
                      })}
                    />
                  </Form.Item>

                  <Form.Item<CreateConnectionX509Form>
                    colon={false}
                    label={
                      <FormattedMessage id="cluster.clusterImportIntegration.connectionDetailsStep.defaultDatabaseNameLabel" />
                    }
                    name={[CONNECTION_FIELD_NAMES.DEFAULT_DATABASE_NAME]}
                    rules={[INPUT_SANITIZATION.MONGODB_DATABASE_NAME]}
                  >
                    <Input
                      placeholder={formatMessage({
                        id: 'cluster.clusterImportIntegration.connectionDetailsStep.defaultDatabaseNamePlaceholder',
                      })}
                    />
                  </Form.Item>

                  <Button
                    type={Button.types.SUBMIT}
                    disabled={!attachedCertificateFile}
                  >
                    <FormattedMessage id="common.next" />
                  </Button>
                </Form>
              )}

              {!createConnection && (
                <Button
                  type={Button.types.SUBMIT}
                  disabled={typeof connection === 'undefined'}
                  onClick={() => {
                    onNext(connections!.find(conn => conn.id === connection)!);
                  }}
                >
                  <FormattedMessage id="common.next" />
                </Button>
              )}
            </>
          );
        }}
      />
    </ConstrainStepWidth>
  );
}

export default ConnectionDetailsStep;
