import { DisplayDate, Text } from '@crate.io/crate-gc-admin';
import { FormattedMessage, useIntl } from 'react-intl';
import CloudUITable from 'src/components/CloudUITable';
import StatusIndicator from 'src/components/StatusIndicator';
import { OPERATION_STATES } from 'src/constants/defaults';
import { Integration } from 'types';
import { INTEGRATION_SOURCES } from '../../ClusterImportIntegration/common/SourceStep/sources';

type IntegraionsListProps = {
  integrations: Integration[];
};

function IntegrationsList({ integrations }: IntegraionsListProps) {
  const { formatNumber } = useIntl();

  const getStatusIndicatorMessage = (integration: Integration) => {
    switch (integration.status) {
      case OPERATION_STATES.IN_PROGRESS:
        // multi-file import
        if (integration.progress.total_files > 1) {
          return (
            <FormattedMessage
              id="cluster.clusterImport.quickStatusImportInProgressFilesText"
              values={{
                file: formatNumber(integration.progress?.processed_files || 0, {
                  notation: 'compact',
                }),
                total: formatNumber(integration.progress?.total_files || 0, {
                  notation: 'compact',
                }),
              }}
            />
          );
        }

        // single file import
        if (integration.progress.records > 0) {
          return (
            <FormattedMessage
              id="cluster.clusterImport.quickStatusImportInProgressRecordsText"
              values={{
                records: formatNumber(integration.progress.records, {
                  notation: 'compact',
                }),
                total: formatNumber(integration.progress.total_records, {
                  notation: 'compact',
                }),
              }}
            />
          );
        }

        // fallback
        return (
          <FormattedMessage id="cluster.clusterImport.quickStatusSettingUpImportText" />
        );
      case OPERATION_STATES.REGISTERED:
      case OPERATION_STATES.SENT:
        return (
          <FormattedMessage id="cluster.clusterImport.quickStatusSettingUpImportText" />
        );
      case OPERATION_STATES.SUCCEEDED:
        return (
          <FormattedMessage id="cluster.clusterImport.quickStatusImportSucceededText" />
        );
      case OPERATION_STATES.FAILED:
        return (
          <FormattedMessage id="cluster.clusterImport.quickStatusImportFailedText" />
        );
      default:
        return null;
    }
  };

  const columns = [
    {
      title: <FormattedMessage id="cluster.clusterImport.nameHeaderText" />,
      render: (integration: Integration) => (
        <span className="overflow-hidden text-ellipsis">{integration.name}</span>
      ),
      width: '25%',
    },
    {
      title: <FormattedMessage id="cluster.clusterImport.typeColumnHeaderText" />,
      responsive: ['lg'],
      render: (integration: Integration) => {
        const selectedSource = INTEGRATION_SOURCES.find(
          el => el.key === integration.type,
        )!;

        return (
          <div className="flex items-center gap-2 text-black">
            {selectedSource.icon}
            <Text pale>
              <FormattedMessage id={selectedSource.title} />
            </Text>
          </div>
        );
      },
      width: '10%',
    },
    {
      render: (integration: Integration) =>
        `${integration.mongodb.database_name}.${integration.mongodb.collection_name}`,
      responsive: ['xl'],
      title: <FormattedMessage id="cluster.clusterImport.sourceHeaderText" />,
      width: '15%',
    },
    {
      dataIndex: ['destination', 'table'],
      responsive: ['xl'],
      title: <FormattedMessage id="cluster.clusterImport.destinationHeaderText" />,
      width: '15%',
    },
    {
      render: (integration: Integration) => (
        <DisplayDate isoDate={integration.dc.created} />
      ),
      responsive: ['lg'],
      title: <FormattedMessage id="cluster.clusterImport.dateColumnHeaderText" />,
      width: '20%',
    },
    {
      render: (integration: Integration) => (
        <StatusIndicator
          message={getStatusIndicatorMessage(integration)!}
          progress={Math.round(integration.progress.percent)}
          status={integration.status}
        />
      ),
      title: <FormattedMessage id="cluster.clusterImport.statusColumnHeaderText" />,
      width: '25%',
    },
    // {
    //   render: () => 'temp',
    //   width: '15%',
    // },
  ];

  return (
    <>
      <CloudUITable
        columns={columns}
        dataSource={integrations}
        pagination={integrations.length > 10}
        rowKey="id"
        showHeader
      />
    </>
  );
}

export default IntegrationsList;
